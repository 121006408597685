import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');
const Home = () => import(/* webpackChunkName: "home" */'../views/Home');
const ProjectsList = () => import(/* webpackChunkName: "home" */'../views/components/Projects/list');
const UsersList = () => import(/* webpackChunkName: "home" */'../views/components/User/userList');
const ProjectAdd = () => import(/* webpackChunkName: "home" */'../views/components/Projects/addProject');
const ModuleList = () => import(/* webpackChunkName: "home" */'../views/components/Modules/moduleList');
const ModuleAdd = () => import(/* webpackChunkName: "home" */'../views/components/Modules/addModule');
const ApiList = () => import(/* webpackChunkName: "home" */'../views/components/APISection/APIListing');
const ApiAdd = () => import(/* webpackChunkName: "home" */'../views/components/APISection/addAPI');
const ApiView = () => import(/* webpackChunkName: "home" */'../views/components/APISection/viewAPI');
const ApiEdit = () => import(/* webpackChunkName: "home" */'../views/components/APISection/editAPI');
const UserAdd = () => import(/* webpackChunkName: "home" */'../views/components/User/addUser');
const ParameterList = () => import(/* webpackChunkName: "home" */'../views/components/APISection/parameterList');
const ApiDetailEdit = () => import(/* webpackChunkName: "home" */'../views/components/APISection/detailEditAPI');
const NotificationList = () => import(/* webpackChunkName: "home" */'../views/components/Notification/notificationList');
const UserNotificationList = () => import(/* webpackChunkName: "home" */'../views/components/Notification/User/notificationView');

const UserDetailEdit = () => import(/* webpackChunkName: "home" */'../views/components/UserDetailedInfo/detailsEdit');
const UserDetailView = () => import(/* webpackChunkName: "home" */'../views/components/UserDetailedInfo/detailsView');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        // project
        {
            path: '/projects/',
            name: 'ProjectList',
            component: ProjectsList
        },

        // User
        {
            path: '/users/',
            name: 'UsersList',
            component: UsersList
        },
        {
            path: '/project-add/',
            name: 'ProjectAdd',
            component: ProjectAdd
        },
        {
            path: '/:project/modules/',
            name: 'Modules',
            component: ModuleList
        },
        {
            path: '/modules/',
            name: 'Modules',
            component: ModuleList
        },
        {
            path: '/module-add/:id/',
            name: 'ModuleAdd',
            component: ModuleAdd
        },
        {
            path: '/apis/:id/',
            name: 'APIs',
            component: ApiList
        },
        {
            path: '/api-add/:id/',
            name: 'APIAdd',
            component: ApiAdd
        },
        {
            path: '/api-edit/:module/:id/',
            name: 'APIEdit',
            component: ApiEdit
        },
        {
            path: '/api-view/:id/',
            name: 'APIView',
            component: ApiView
        },
        {
            path: '/api-detail-edit/:module/:id/',
            name: 'APIDetailEdit',
            component: ApiDetailEdit
        },
        {
            path: '/param-list/',
            name: 'Parameters',
            component: ParameterList
        },
        {
            path: '/add-user/',
            name: 'AddUser',
            component: UserAdd
        },
        {
            path: '/notifications/',
            name: 'Notifications',
            component: NotificationList
        },
        {
            path: '/users/notifications/',
            name: 'Users Notifications',
            component: UserNotificationList
        },
        {
            path: '/detail-edit/',
            name: 'Users Detail Edit',
            component: UserDetailEdit
        },
        {
            path: '/details/:id/',
            name: 'Users Details',
            component: UserDetailView
        }
    ]
};
