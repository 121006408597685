const Layout = () => import(/* webpackChunkName: "login" */'../layouts/BlankLayout');
// const login = () => import(/* webpackChunkName: "home" */'../views/auth/Login');
const loginView = () => import(/* webpackChunkName: "home" */'../views/auth/LoginView');
const ForgetPassword = () => import(/* webpackChunkName: "home" */'../views/auth/ForgetPassword');
const InitialForgetPassword = () => import(/* webpackChunkName: "home" */'../views/auth/InitialPasswordReset');
const Logout = () => import(/* webpackChunkName: "home" */'../views/auth/Logout');
const ChangePassword = () => import(/* webpackChunkName: "home" */'../views/auth/ChangePassword');

export default {
    path: '/auth/',
    name: 'AuthLayout',
    redirect: '/login/',
    component: Layout,
    children: [
        // {
        //     path: '/login/',
        //     name: 'Login',
        //     component: login
        // },
        {
            path: '/login/',
            name: 'LoginView',
            component: loginView
        },
        {
            path: '/reset-password/',
            name: 'ResetPassword',
            component: ForgetPassword
        },
        {
            path: '/logout/',
            name: 'Logout',
            component: Logout
        },
        {
            path: '/initial-reset-password/',
            name: 'InitialResetPassword',
            component: InitialForgetPassword
        },
        {
            path: '/change-password/',
            name: 'ChangePassword',
            component: ChangePassword
        }
    ]
};
