const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
}

export default {
    auth: {
        login: baseUrl + 'auth/login/',
        statusChange: baseUrl + 'auth/user-status-update/',
        userAddEdit: baseUrl + 'auth/user-add-edit/',
        forgetPassword: baseUrl + 'auth/forgot-password/',
        InitialReset: baseUrl + 'auth/initial-password-reset-view/',
        UserList: baseUrl + 'auth/users-list/',
        UserDetails: baseUrl + 'auth/users-details/',
        Logout: baseUrl + 'auth/logout/',
        status: baseUrl + 'auth/status/',
        passwordReset: baseUrl + 'auth/master-password-reset/',
        ChangePassword: baseUrl + 'auth/change-password/',
        DocumentAddEdit: baseUrl + 'auth/user-documents-add-edit/',
        DocumentList: baseUrl + 'auth/user-documents-list/',
        DocumentDelete: baseUrl + 'auth/user-documents-delete/',
        EducationList: baseUrl + 'auth/user-education-list/',
        EducationAddEdit: baseUrl + 'auth/user-education-add-edit/'

    },
    projects: {
        UsersVueSelect: baseUrl + 'auth/users-vue-select/',
        AddEdit: baseUrl + 'project-manager/project/add-edit/',
        List: baseUrl + 'project-manager/project/list/',
        Delete: baseUrl + 'project-manager/project/delete/',
        Details: baseUrl + 'project-manager/project/detail/',
        reportDownload: baseUrl + 'project-manager/project-doc/pdf-download/'

    },
    Module: {

        AddEdit: baseUrl + 'project-manager/module/add-edit/',
        List: baseUrl + 'project-manager/module/list/',
        Delete: baseUrl + 'project-manager/module/delete/',
        Details: baseUrl + 'project-manager/module/detail/'

    },
    ApiSection: {

        AddEdit: baseUrl + 'project-manager/api-details/add-edit/',
        List: baseUrl + 'project-manager/api-details/list/',
        Delete: baseUrl + 'project-manager/api-details/delete/',
        Details: baseUrl + 'project-manager/api-details/detail/'

    },
    Notification: {
        AddEdit: baseUrl + 'notification/add-edit/',
        List: baseUrl + 'notification/list/',
        Details: baseUrl + 'notification/details/',
        Delete: baseUrl + 'notification/delete/'
    }

};
